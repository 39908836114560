<template>
  <div class="text-center">
    <v-btn
      color="red lighten-2"
      dark
      :loading="loading"
      @click="exportData()"
    >
      {{ $t('misc.export') }}
    </v-btn>
  </div>
</template>
<script>
import { downloadBlob } from '@/misc/utils';
import mime from 'mime-types';

/**
 * Button to make an export of data
 */
export default {
  name: 'ExportButton',
  props: {
    query: {
      type: Object,
      required: true,
    },
    csvData: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    /**
     * Request the backend to create an export
     */
    async backendExport() {
      this.loading = true;
      const blob = await this.$store.dispatch('crud/EXPORT', this.query);
      const extension = mime.extension(blob.type);
      this.loading = false;
      downloadBlob(blob, `${this.query.object}.${extension}`);
    },
    /**
     * Export from csvData if csvData prop is set or from a backend request
     */
    async exportData() {
      if (this.csvData) {
        const blob = new Blob([this.csvData], { type: 'text/csv' });
        downloadBlob(blob, `${this.query.object}.csv`);
      } else {
        await this.backendExport();
      }
    },
  },
};
</script>
