<template>
  <v-container grid-list-xs text-xs-start class="list-layout">
    <v-layout align-start justify-center>
      <v-flex
        xs0
        md4
        lg3
        v-if="showFilters
          || permissions.indexOf('create') > -1
          || options.canImport
          || options.export">
        <div class="mt-6 ml-6">
          <Filters
            :key="`${pageName}_filter`"
            :filters.sync="filters"
            :bottom-sheet="$vuetify.breakpoint.smAndDown"
            :show-filters="openFilters"
            v-on:hide="openFilters = false"
          >
            <template v-slot:footer>
              <v-layout align-center justify-center row fill-height
                v-if="permissions.indexOf('create') > -1
                  || options.canImport
                  || options.export">
                <v-flex
                  v-if="permissions.indexOf('create') > -1"
                  xs0 sm6>
                  <v-btn
                    class="white--text"
                    color="primary"
                    :to="{ path: itemRoute + '/' + 'new' }"
                    link
                  >
                    <v-icon>mdi-plus</v-icon>
                    {{ $t('crud.add') }}
                  </v-btn>
                </v-flex>
                <v-flex
                  v-if="permissions.indexOf('create') > -1
                    && options.canImport"
                  xs0 sm6>
                  <import-modal
                    :exampleLink="$t('import.exampleLink')"
                  />
                </v-flex>
                <v-flex
                  v-if="options.export"
                  xs0 sm6>
                  <export-button
                    :query="queryExport"
                    :csv-data="csvData"
                  />
                </v-flex>
              </v-layout>
            </template>
          </Filters>
        </div>
      </v-flex>
      <v-flex xs12 class="mt-6 mr-2">
        <Table
          :key="`${pageName}_table`"
          class="ma-0"
          :object="object"
          :headers="headers"
          :itemRoute="itemRoute"
          :hits="hits"
          :search.sync="search"
          :pagination.sync="pagination"
          :sort.sync="sort"
          :total="total"
          :selected.sync="selected"
          :searchable="searchable"
          :searchBarTitle="searchBarTitle"
          :loading="loading"
          :selectable="false"
          :readable="permissions.indexOf('read') > -1"
          :updatable="permissions.indexOf('update') > -1"
          :deletable="permissions.indexOf('delete') > -1"
          :read-status="readStatus"
          :queryParams="queryParams"
          :height="height"
          :csvData.sync="csvData"
          @refreshData="fetchDataDebounce()"
        >
          <template v-slot:filter v-if="$vuetify.breakpoint.smAndDown && showFilters">
            <v-icon
              v-on:click="openFilters = true"
            >
              mdi-filter-variant
            </v-icon>
          </template>
        </Table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from '@/misc/lodash';
import Table from '@/components/crud/Table.vue';
import Filters from '@/components/crud/Filters.vue';
import ImportModal from '@/components/crud/ImportModal.vue';
import ExportButton from '@/components/crud/ExportButton.vue';
import BasePageMixin from '@/mixins/basePage';
import FilterableMixin from '@/mixins/filterable';

export default {
  name: 'List',
  mixins: [
    BasePageMixin,
    FilterableMixin,
  ],
  components: {
    Table,
    Filters,
    ImportModal,
    ExportButton,
  },
  data: () => ({
    pagination: {
      page: 1,
      itemsPerPage: 5,
    },
    sort: {
    /*
      It't a path => order object
      path: 'ASC',
    */
    },
    keyPref: null,
    preferences: null,
    loading: true,
    firstLoad: true,
    openFilters: false,
    currentObject: null,
    fetchDataDebounce: () => {},
    // Variable is true if user navigated to this page with a specific url
    specifiedUrl: false,
    height: 338,
    csvData: '',
  }),
  computed: {
    ...mapGetters('settings', ['getItemsPerPage']),

    /**
     * Get userPreferences from Auth store
     *
     * @return {Record<string, unknown>} Return object of userPreferences
     */
    storedPreferences() {
      const preferences = this.$store.state.auth.currentUserffly4u._source.listPreferences || {};
      return preferences[this.keyPref] || {};
    },
    /**
     * Get userPreferences from Auth store
     *
     * @return {Record<string, unknown>} Return object of userPreferences
     */
    userPreferences() {
      return this.$store.state.auth.currentUserffly4u[this.preferencesPath] || {};
    },
    preferencesPath() {
      return `listPreferences-${this.keyPref}`;
    },
    pageName() {
      return this.$store.state.settings.currentPage.route.name;
    },
    itemRoute() {
      return this.options.itemRoute;
    },
    permissions() {
      return this.options.permissions;
    },
    searchable() {
      return this.options.searchable;
    },
    readStatus() {
      return this.options.readStatus;
    },
    selected: {
      get() {
        const selected = [];
        if (this.$route.query.selected) {
          try {
            const s = JSON.parse(this.$route.query.selected);

            if (Array.isArray(s)) {
              s.forEach((sel) => selected.push({ id: sel }));
            }
          } catch (e) {
            console.warn('Wrongly formed selected query');
          }
        }
        return selected;
      },
      set(value) {
        const query = { ...this.$route.query };
        query.selected = Array.isArray(value) && value.length > 0
          ? JSON.stringify(value.map((sel) => sel.id))
          : undefined;

        if (typeof query.selected === 'undefined') {
          delete query.selected;
        }

        this.$router.replace({ ...this.$route, query }).catch(() => {});
      },
    },
    search: {
      get() {
        return this.$route.query.search || '';
      },
      set(_value) {
        const value = _value.length > 0 ? _value : undefined;
        const searchIndex = this.filters.findIndex(({ search }) => search);
        if (searchIndex !== -1) {
          // this.filters[searchIndex].value = value;

          let newFilter = this.filters[searchIndex];
          newFilter = { ...newFilter, value };
          this.$set(this.filters, searchIndex, newFilter);
        }
        this.$router.replace({
          ...this.$route,
          query: { ...this.$route.query, search: value },
        }).catch(() => {});
      },
    },
    itemsPerPage() {
      let number = 5;
      if (this.getItemsPerPage) {
        number = this.getItemsPerPage;
      }
      // Priority URL over configuration
      if (this.$route.query.limit) {
        number = Number.parseInt(this.$route.query.limit, 10);
      } else if (this.options.itemsPerPage) {
        number = Number.parseInt(this.options.itemsPerPage, 10);
      }
      return number;
    },
    page() {
      const page = Number.parseInt(this.$route.query.page, 10);
      return Number.isNaN(page) ? 1 : page;
    },
    headers() {
      const headers = (this.fields.read || [])
        .map((header) => ({
          text: this.$t(header.label),
          value: header.path,
          sortable: !!header.sortable,
          ...header,
        }));

      if (this.permissions.some((perm) => ['read', 'update', 'delete'].includes(perm))) {
        const value = this.options.objectId || 'id';
        headers.push({
          text: this.$t('crud.actions'),
          value,
          sortable: false,
          format: { type: 'actions' },
        });
      }
      return headers;
    },
    hits() {
      if (!this.firstLoad
        && this.$store.state.crud
        && _.get(this.$store.state.crud, `data.${this.object}.body`, false) !== false) {
        return this.$store.state.crud.data[this.object].body
          .filter((hit) => (hit.current) && (hit.id !== 'HORS_SITE'))
          .sort((a, b) => (a.order - b.order));
      }
      return [];
    },
    total() {
      if (!this.firstLoad
        && this.$store.state.crud
        && _.get(this.$store.state.crud, `data.${this.object}.totalResult`, false) !== false) {
        return this.$store.state.crud.data[this.object].totalResult;
      }
      return 0;
    },
    max() {
      let max = 100;
      if (this.$store.state.crud
        && _.get(this.$store.state.crud, `data.${this.object}.max`, false) !== false) {
        max = this.$store.state.crud.data[this.object].max;
      }
      return max;
    },
    queryExport() {
      const object = this.object === 'poi/inventory' ? 'poi' : this.object;
      return {
        object: `${object}/export`,
        body: this.dataQuery,
        alias: this.alias,
        all: this.pagination.itemsPerPage === -1,
      };
    },
    routeParams() {
      const query = {
        ...this.$route.query,
        page: this.pagination.page,
        limit: this.pagination.itemsPerPage,
      };

      this.filters.forEach(({ fid, value, search }) => {
        if (!_.isEmpty(value)) {
          query[search ? 'search' : fid] = value;
        } else if (typeof query[search ? 'search' : fid] !== 'undefined') {
          delete query[search ? 'search' : fid];
        }
      });

      if (this.selected.length > 0) {
        query.selected = JSON.stringify(this.selected.map((sel) => sel.id));
      }
      return query;
    },
    dataQuery() {
      const { before, after, ...filters } = this.filterValues;
      const query = {
        page: this.pagination.page,
        limit: this.pagination.itemsPerPage,
        ...this.queryParams,
        before,
        after,
        filters,
      };
      if (!_.isEmpty(this.sort)) {
        query.sort = this.sort;
      }
      return query;
    },
    searchBarTitle() {
      const item = this.filters.find((filter) => filter.component === undefined && filter.search);
      return item?.label || 'crud.search';
    },
  },
  watch: {
    pageName: {
      handler() {
        this.sort = {};
        // When we switch page without changing view
        const selected = [];
        if (this.$route.query.s) {
          try {
            const s = JSON.parse(this.$route.query.s);

            if (Array.isArray(s)) {
              s.forEach((sel) => selected.push({ id: sel }));
            }
          } catch (e) {
            console.warn('Wrongly formed selected query');
          }
        }

        this.selected = selected;
        this.loading = true;
        this.firstLoad = true;
        this.openFilters = false;
        this.fetchData(true);
        let currentPath = this.$router.currentRoute.matched[0].path;
        if (currentPath.length === 0) {
          currentPath = 'home';
        }
        this.keyPref = currentPath;
        if (_.isEmpty(this.$route.query)) {
          this.specifiedUrl = true;
          if (this.userPreferences) {
            if (this.userPreferences.query) {
              this.$router.replace({ ...this.$route, query: this.userPreferences.query })
                .catch(() => {});
            }
            if (this.userPreferences.filters) {
              // console.log('update filters');
              this.filters = this.userPreferences.filters;
            }
            if (this.userPreferences.sort) {
              // console.log('update sort');
              this.sort = this.userPreferences.sort;
            }
            if (this.userPreferences.pagination) {
              this.pagination = this.userPreferences.pagination;
            }
          }
        }
      },
      immediate: true,
    },
    headers() {
      if (this.headers.length === 0 || (this.headers.length === 1 && this.headers[0].value === 'action')) {
        this.$router.push('/');
      }
    },
    itemsPerPage: {
      handler(value) {
        this.pagination.itemsPerPage = value;
        this.$store.commit('settings/SET_ITEMS_PER_PAGE', value);
        // console.log('itemsPerPage triggerred');
        if (!this.firstLoad) {
          const prefs = {
            ...this.userPreferences,
            itemsPerPage: this.itemsPerPage,
            pagination: this.pagination,
          };
          if (prefs.query && prefs.query.limit) {
            prefs.query.limit = this.itemsPerPage;
          }
          this.updatePrefs(prefs);
        }
      },
      immediate: true,
    },
    page: {
      handler(value) {
        this.pagination.page = value;

        if (!this.firstLoad) {
          const prefs = {
            ...this.userPreferences,
            pagination: this.pagination,
          };
          if (prefs.query && prefs.query.page) {
            prefs.query.page = this.pagination.page;
          }
          this.updatePrefs(prefs);
        }
      },
      immediate: true,
    },
    dataQuery: {
      handler() {
        this.fetchDataDebounce();
      },
      deep: true,
      immediate: true,
    },
    sort() {
      if (!this.firstLoad) {
        this.updatePrefs({ ...this.userPreferences, sort: this.sort });
      }
    },
    max() {
      this.filters = this.filters.map((filter) => {
        if (filter.component === 'sliderField' && filter.options && filter.options.max) {
          filter.options.max = this.max;
        }
        return filter;
      });
    },
  },
  beforeMount() {
    this.height = _.get(this.options, 'height', -1);
  },
  mounted() {
    this.fetchDataDebounce = _.debounce(this.fetchData, 500);

    // If height <= 0 use full-height
    if (this.height <= 0) {
      this.height = Math.max(this.$el.offsetHeight - 129, 279);
      this.resizeListener = () => {
        this.height = Math.max(this.$el.offsetHeight - 129, 279);
      };
      window.addEventListener('resize', this.resizeListener);
    }
    // Set options for table
    if (this.getItemsPerPage) {
      this.pagination.itemsPerPage = this.getItemsPerPage;
    }

    let currentPath = this.$router.currentRoute.matched[0].path;
    if (currentPath.length === 0) {
      currentPath = 'home';
    }
    this.keyPref = currentPath;
  },
  methods: {
    async fetchData(replace = false) {
      this.loading = true;

      this.$nextTick(async () => {
        if (this.options?.requestType === 'get') {
          await this.$store.dispatch('crud/FETCH', {
            object: this.object,
            alias: this.alias,
            params: this.dataQuery,
            all: this.pagination.itemsPerPage === -1,
          });
        } else {
          await this.$store.dispatch('crud/SEARCH', {
            object: this.object,
            alias: this.alias,
            body: this.dataQuery,
            all: this.pagination.itemsPerPage === -1,
          });
        }

        const route = { ...this.$route, query: this.routeParams };

        this.updatePrefs({
          query: this.$route.query,
          itemsPerPage: this.itemsPerPage,
          filters: this.filters,
          pagination: this.pagination,
          sort: this.sort,
        });

        // Fix "NavigationDuplicated"
        // See https://github.com/vuejs/vue-router/issues/2872
        if (replace || this.firstLoad) {
          this.$router.replace(route).catch(() => {});
        } else {
          this.$router.push(route).catch(() => {});
        }

        this.$nextTick(() => {
          this.loading = false;
          this.firstLoad = false;
        });
      });
    },
    /**
     * Update the preferences of List
     * Merge new preferences with previous
     *
     * @param {Object} preferences object of the page
     */
    updatePrefs(preferences) {
      this.$store.dispatch('auth/SET_SELF', {
        path: this.preferencesPath,
        value: preferences,
      });
    },
  },
};
</script>

<style lang="scss">
.list-layout {
  & > .layout {
    height: calc(100vh - 56px);
    @media (min-width: 960px) {
      height: calc(100vh - 64px);
    }
  }
}
</style>
