import _ from '../misc/lodash';

/**
 * Mixin for filterable components
 */
export default {
  computed: {
    currentPage() {
      return this.$store.state.settings.currentPage;
    },
    options() {
      const { options } = this.currentPage;
      return Array.isArray(options) ? options[0] : options;
    },
    pathToId() {
      return _.get(this.options, 'pathToId', null);
    },
    objectId() {
      if (this.pathToId !== null) {
        return _.get(this[this.pathToId.object], this.pathToId.path);
      }
      return null;
    },
    object() {
      return this.options.object;
    },
    alias() {
      return this.options.alias || this.options.object || 'default';
    },
    queryParams() {
      return this.options.queryParams || {};
    },
    fields() {
      return this.options.fields || {};
    },
    searchFields() {
      return this.fields.search || [];
    },
  },
};
