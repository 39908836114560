import _ from '@/misc/lodash';

/**
 * Mixin for filterable components
 */
export default {
  data: () => ({
    filters: [],
  }),
  computed: {
    /**
     * Compute filters value by path
     *
     * @example
     * {
     *   "path": "value"
     * }
     *
     * @return {Record<string, string>}
     */
    filterValues() {
      return this.filters
        .filter(({ value }) => !_.isEmpty(value))
        .reduce((acc, { path, value }) => {
          if (typeof path === 'object' && Array.isArray(path.fields)) {
            path.fields.forEach((field) => {
              acc[field] = value;
            });
            return acc;
          }
          return { ...acc, [path]: value };
        }, {});
    },
    /**
     * Define if filters are visible
     *
     * @return {boolean}
     */
    showFilters() {
      return this.filters.some((filter) => typeof filter.component !== 'undefined');
    },
  },
  watch: {
    searchFields: {
      handler(values) {
        this.filters = this.computeFilters(values);
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * Compute filters
     *
     * @return {Record<string, unknown>[]} Array of filter objects
     */
    computeFilters(fields) {
      const filters = fields.map((filter) => {
        let value;
        if (filter.search && typeof this.$route.query.search !== 'undefined') {
          value = this.$route.query.search;
        } else if (typeof this.$route.query[filter.fid] !== 'undefined') {
          value = this.$route.query[filter.fid];
        }

        if (filter.default && _.isEmpty(value) && Object.keys(this.$route.query).length === 0) {
          value = filter.default;
        }

        if ((filter.component === 'autocompleteField' || filter.component === 'selectField')
            && filter.multiple !== false
            && !Array.isArray(value) && typeof value !== 'undefined') {
          value = [value];
        }

        return { value, ...filter };
      });

      return filters;
    },
  },
};
