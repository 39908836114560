<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          color="red lighten-2"
          dark
          v-on="on"
        >
          {{ $t('misc.import') }}
        </v-btn>
      </template>
      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
        >
          {{ $t('misc.import') }}
        </v-card-title>
        <v-card-text class="mt-6">
          {{ $t(description) }}
        </v-card-text>
        <v-card-text v-if="exampleLink">
          <a target="blank" :href="exampleLink" :title="$t('misc.example')">
            {{ $t('misc.example') }}
          </a>
        </v-card-text>
        <v-card-text>
          <v-file-input
            v-model="value"
            :label="$t('misc.importFile')"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ref="file"
          />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :disabled="!value || value.length === 0"
            :loading="loading"
            @click="send"
          >
            {{ $t('misc.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from 'axios';
import _ from '@/misc/lodash';

/**
 * Button to import data
 */
export default {
  name: 'ImportModal',
  props: {
    description: {
      type: String,
      default: 'misc.importDescription',
    },
    exampleLink: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      value: null,
      importId: null,
      snackbarId: null,
    };
  },
  computed: {
    /**
     * Observe response of import
     *
     * @return {boolean} Return boolean if import is ready or not
     */
    messages() {
      return this.$store.state.realtime.messages.filter((message) => {
        const importId = _.find(
          message._source.metadata,
          {
            name: 'importId',
            type: 'keyword',
          },
        ) || false;

        return importId === this.importId;
      });
    },
  },
  watch: {
    /**
     * When import is ready reset state
     */
    messages() {
      if (this.loading && this.messages.length >= 2) {
        this.$store.commit('snackbars/REMOVE_MESSAGE', this.snackbarId);
        let data = {
          id: null,
          title: 'Import',
          description: 'Data imported',
          color: 'success',
          timeout: 5000,
        };

        const error = this.messages.find((message) => _.find(
          message._source.metadata,
          { name: 'error' },
        ) || false);
        if (typeof error !== 'undefined') {
          data = {
            ...data,
            title: 'Error',
            description: error._source.value,
            color: 'error',
          };
        }
        this.$store.commit('snackbars/ADD_MESSAGE', data);
        this.$nextTick(() => {
          this.loading = false;
          this.dialog = false;
          this.importId = null;
          this.snackbarId = null;
        });
      }
    },
  },
  methods: {
    /**
     * Request to upload data
     */
    async send() {
      this.loading = true;

      this.$nextTick(async () => {
        try {
          let url = window.kuzzleConfig.host;

          if (window.kuzzleConfig.options.sslConnection) {
            url = `https://${url}`;
          } else {
            url = `http://${url}`;
          }
          url = `${url}:${window.kuzzleConfig.options.port}`;
          url = `${url}/_plugin/application-connect/imports/new`;
          const formData = new FormData();
          formData.append('application', this.$store.state.settings.currentApplication);
          formData.append('content', this.value);

          const { data } = await axios(
            {
              method: 'post',
              url,
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.user_token}`,
              },
              data: formData,
            },
          );

          if (data) {
            this.value = null;
            this.importId = data.result.id;
            const snackData = {
              id: null,
              title: 'Import',
              description: 'Import underway',
              color: 'primary',
              loading: true,
              timeout: 0,
            };
            this.$store.commit('snackbars/ADD_MESSAGE', snackData);
            this.snackbarId = snackData.id;
          } else {
            this.importId = null;
            this.snackbarId = null;
            throw new Error('Error while importing data');
          }
          setTimeout(() => {
            // we put a timeout in order for the event to be visible
            this.dialog = false;
          }, 500);
        } catch (error) {
          const data = {
            id: null,
            title: 'Error',
            description: error.message || 'An error occurred',
            color: 'error',
            timeout: 5000,
          };
          this.$store.commit('snackbars/ADD_MESSAGE', data);
          setTimeout(() => {
            // we put a timeout in order for the event to be visible
            this.loading = false;
          }, 500);
        }
      });
    },
  },
};
</script>
